import APP_CONST from '@/constants/AppConst';
import { programRecruitment } from '@/services/analytics/analyticsService';
import analyticsStore from '@/store/modules/analyticsStore';
import APP_UTILITIES from '@/utilities/commonFunctions';
import { Vue, Component, Prop } from 'vue-property-decorator';
import AnalyticsProgramDropdown from '../commonComponents/analyticsProgramDropdown/AnalyticsProgramDropdown.vue';
import LineChart from '@/chartcomponents/linecomponent/LineChart.vue';
import DropdownList from '@/ui-components/dropdownListBx/DropdownList.vue';
import { ScreenText } from '@/lang/ScreenText';
import Printutility from '@/utilities/PrintComponentMethod';
import DataDashboardSidepanel from '@/components/datadashboardcomponent/datadashboardsidepanel/DataDashboardSidepanel.vue';
import LineComponent from '@/chartcomponents/linecomponent/LineComponent.vue'
import programConfigureStore from '@/store/modules/programConfigure';
import { getSessionsForProgram } from '@/services/sessions/api';
import dashboardStore from '@/store/modules/dashboardStore';
import UIkit from "uikit";
import leftSidepanel from '@/popupcomponents/leftsidepanel/leftSidepanel.vue';
import DataDashboardWelcome from '@/components/datadashboardcomponent/datadashboardwelcome/DataDashboardWelcome.vue';
import  BouncingPreloaderComponent  from '@/commoncomponents/bouncingpreloadercomponent/BouncingPreloaderComponent.vue';

@Component({
    components: {
      'analytics-program-dropdown' : AnalyticsProgramDropdown,
      'line-chart': LineChart,
      'dropdown':DropdownList,
      'sidepanelDesktop':DataDashboardSidepanel,
      'line-chart-pdf':LineComponent,
      'sidepanelMobile':leftSidepanel,
      'welcome-popup' : DataDashboardWelcome,
      "bouncing-preloader": BouncingPreloaderComponent,
    }
  })

export default class QualityAnalyticsComponent extends Vue {
  public isApiResponse:boolean = false;
  public yScaleLabel:string = APP_CONST.QUALITY_SCORES;
  public singleDropdownConfig = APP_CONST.USERS_SINGLE_DROPDOWN_CONFIG;
  public emptyStateStyle:any={};
  public isNoQualityScore:boolean = false;
  private objScreenText: ScreenText = new ScreenText();
  public responseRecieved:boolean = false;
  public isQualityPdfDownloadInProgress = false;
  public hidequalityScore: boolean = false;
  public pdfChartData:any = [];
  public siteList:any = [];
  public isMobileOnly:boolean = false;
  public getScreenText(key: string): string {
    return this.objScreenText.getScreenText(key);
}
public mobileView:boolean = false;
public isChartMount:boolean = false;
public isLineChartLoaderVisible: boolean = false;

  @Prop()
  userDetails!: any;

  @Prop()
  roleId!: number;


  beforeMount(){
    this.isMobileOnly = APP_UTILITIES.isMobile()
    this.getProgramRecruitment();
    this.mobileView = document.documentElement.clientWidth <= 1024 ? true : false
  }

  fetchAccountDetails(programId: string): any {
    programConfigureStore.getProgramConfigObjProgramPage(parseInt(programId)).then(response => {
      let responseData: any = ((<any>response).data === undefined) ? null : (<any>response).data
      this.userDetails.programStartDate = responseData.programDto.startDate ? responseData.programDto.startDate : this.userDetails.programStartDate;
      this.userDetails.programEndDate = responseData.programDto.endDate ? responseData.programDto.endDate : this.userDetails.programEndDate;
    })
  }

  openSidePanel() {
    UIkit.offcanvas('#left-sidepanel').show();
  }


  async getProgramRecruitment(){
    this.isLineChartLoaderVisible = true;
    this.isApiResponse = false;
    let accountId: string | number | null = APP_UTILITIES.getCookie('accountId');
    accountId = accountId ? Number(accountId) : 0
    analyticsStore.mutateDropdownResponseStatus(0)
    let response = await programRecruitment(accountId);
    let dropdownValue = response.data
    let  responseStatus = Number(response.status)
    analyticsStore.mutateDropdownResponseStatus(responseStatus)
    analyticsStore.mutateAnalyticsProgramDropdown(dropdownValue);
    this.getSelectedProgram();
    setTimeout(()=>{
      this.getProgramSites();
      this.fetchAccountDetails(this.selectedProgramData.programId)
      this.getSite(this.selectedProgramData.programId);
  }, 200)
    this.isApiResponse = true;
}

  get chartQualityData(){
    return analyticsStore.chartDataQuality;
  }

  get totalQualityPercent(){
    return analyticsStore.totalQualityPercent;
  }

  get selectedProgramData() {
    return analyticsStore.slectedProgram
  }

  get selectedSite() {
    return analyticsStore.adaselectedSite
  }

  get siteDropdown() {
    return analyticsStore.selectedprogramSites
  }

  getProgramSites(){
    this.isLineChartLoaderVisible = true;
    analyticsStore.mutateSelectedSite({id:0,value:'All Sessions'})
    this.responseRecieved = false;
    let authKey = APP_UTILITIES.authTokenKeyToManage() ? APP_UTILITIES.authTokenKeyToManage() : "";
    let storeddata:any = APP_UTILITIES.getFilteredColumns(`${authKey}-qualitySiteSessionData`);
    storeddata = storeddata? JSON.parse(storeddata) : '';
    if(this.selectedProgramData && this.selectedProgramData.programId){
        analyticsStore.getProgramSitesQuality(this.selectedProgramData.programId).then((response: any)=>{
          if(response && response.length == 1 && storeddata.hasOwnProperty([this.selectedProgramData.programId])){
            this.isChartMount = true;
          }else{
            this.isChartMount = false;
          }
          analyticsStore.getQualityPersistence({programId: this.selectedProgramData.programId}).then(()=>{
            analyticsStore.renderQualityChart(this.selectedSite).then(()=>{
              this.responseRecieved = true;
              this.isLineChartLoaderVisible = false;

            });
          })
          let payload = {
            programId : this.selectedProgramData.programId,
            siteId: this.selectedSite.id
          }
          this.welcomePopupApi(payload);
        })
    }
}

    welcomePopupApi(payload: {  programId: number, siteId: number }){
      dashboardStore.showWelcomePopup(payload).then((data: any) => {
        /* istanbul ignore else */
        if(data.data==false){
        if(UIkit.modal('#quality-welcome') ) {
          UIkit.modal('#quality-welcome').show();
        }
      }
    })
  }

  closeWelcomePopup(ev: {id: string}) {
    if(UIkit.modal(`#${ev.id}`)) {
        UIkit.modal(`#${ev.id}`).hide();
    }
    dashboardStore.postWelcomeInformation({siteId: this.selectedSite.id, programId: this.selectedProgramData.programId});
  }

getSelectedProgram(){
    if(APP_UTILITIES.getFilteredColumns('qualityselectedprogram')){
        let program:any = APP_UTILITIES.getFilteredColumns('qualityselectedprogram');
        let selectedProgram = JSON.parse(program);
        analyticsStore.mutateSelectedProgramName(selectedProgram)
    }else{
        analyticsStore.programNameSelection();
    }
}

get lineChartStyle(){
let qualityDataLength:number = 0;
if(this.isMobileOnly){
  qualityDataLength= (this.chartQualityData && this.chartQualityData.labels && this.chartQualityData.labels.length) ? this.chartQualityData.labels.length * (this.chartQualityData.labels.length * 50 > 110  ? 110 :this.chartQualityData.labels.length * 50) : 0;
  }else{
    qualityDataLength= (this.chartQualityData && this.chartQualityData.labels.length) ? this.chartQualityData.labels.length * (this.chartQualityData.labels.length * 8 > 90 ? 90:this.chartQualityData.labels.length * 8) : 0;
  }
  let width:number = this.isMobileOnly ? document.documentElement.clientWidth : 1040;
        let styles: any = {
            width: qualityDataLength>width?`${qualityDataLength}px`:'100%',
            height: "460px",
            position: "relative"
        }
     return styles;
  }

  get emptyStateMessage(){
    let emptyStateMessage = APP_CONST.BLANK;
    this.emptyStateStyle = {};
    this.isNoQualityScore = this.chartQualityData && this.chartQualityData.datasets && Object.keys(this.chartQualityData.datasets).every((element:any) => this.chartQualityData.datasets[element].data && !this.chartQualityData.datasets[element].data.length);
    if(this.siteDropdown== APP_CONST.BLANK||this.isNoQualityScore){
      emptyStateMessage = APP_CONST.NO_QUALITY_DATA;
      this.emptyStateStyle = {
        bottom:this.responseRecieved?this.isMobileOnly?'123px':'100px':this.isMobileOnly?'95px':'72px',
        width:this.isMobileOnly?'225px':'460px',
      };
    }

    return emptyStateMessage;
  }

  singleSiteSelection(site: any) {
  this.isLineChartLoaderVisible = true;
   setTimeout(() => {
    analyticsStore.mutateSelectedSite(site)
    this.responseRecieved = false;
    if(site && site != {}){
      this.hidequalityScore = true;
      if(this.siteDropdown && this.siteDropdown != {} && !this.isChartMount){
        analyticsStore.locallyStoreQualitySite({ programId: this.selectedProgramData.programId, site: site})
        analyticsStore.renderQualityChart(this.selectedSite).then(()=>{
          this.responseRecieved = true;
          this.isLineChartLoaderVisible = false;
        });
      }
      this.scrollReset();
    }
   }, 600);
  }

  scrollReset() {
    let elem: any = document.getElementById('line-chart') as HTMLElement;
    elem && elem.scrollTo(0, 0);
  }

selectProgramData(selectedProgram:any){
  this.isLineChartLoaderVisible = true;
  let authKey = APP_UTILITIES.authTokenKeyToManage() ? APP_UTILITIES.authTokenKeyToManage() : "";
  let storeddata:any = APP_UTILITIES.getFilteredColumns(`${authKey}-qualitySiteSessionData`);
  storeddata = storeddata? JSON.parse(storeddata) : '';
    APP_UTILITIES.setFilteredColumns('qualityselectedprogram', selectedProgram)
    this.responseRecieved = false;
    if(selectedProgram && selectedProgram){
      this.fetchAccountDetails(selectedProgram.programId)
      this.getSite(selectedProgram.programId);
      analyticsStore.getProgramSitesQuality(selectedProgram.programId).then((response: any)=>{
        if(response && response.length == 1 && storeddata.hasOwnProperty([this.selectedProgramData.programId])){
          this.isChartMount = true;
        }else{
          this.isChartMount = false;
        }
        analyticsStore.mutateSelectedSite({id:0,value:'All Sessions'})
        this.fetchAccountDetails(selectedProgram.programId)
        this.getSite(selectedProgram.programId);
        analyticsStore.getQualityPersistence({programId: this.selectedProgramData.programId}).then(()=>{
          analyticsStore.renderQualityChart(this.selectedSite).then(()=>{
            this.responseRecieved = true;
            this.isLineChartLoaderVisible = false;
          });
        })
      })
  }
}

getSite(programId: number) {
  getSessionsForProgram(programId).then((res: any) => {
    this.getSiteByProgramIdResponse(res);
  });
}

getSiteByProgramIdResponse(res :any)
{
  this.siteList = [];
  if (res.status === APP_CONST.RESPONSE_200) {
    const data = res.data;
    if (data) {
      let sitesFill: any = [];
      data.forEach((item: any) => {
        sitesFill.push({ name: item.sessionName, id: item.sessionId, startDate: item.startDateTime, endDate: item.endDateTime });
      })
      this.siteList = sitesFill;
    }
  }
}

formateDate(date: any) {
  if (!date) return date;
  return APP_UTILITIES.formatDate(date);
}

syncScroll(){
  let div1 = document.getElementById('line-chart-quality-1')
  let div2 = document.getElementById('line-chart-quality-2')
  let isSyncingLeftScroll:boolean = false;
  let isSyncingRightScroll:boolean = false;
  if (!isSyncingLeftScroll) {
    isSyncingRightScroll = true;
    if(div1 && div2){
    div2.scrollLeft = div1.scrollLeft
    }
  }
  isSyncingLeftScroll = false;

}

updateGraphQuality(scoreDataUpdated: any, week: number, averageScore: number) {
  if(this.selectedSite.id == 0||(this.selectedSite.id !=0 && scoreDataUpdated.sessionId == this.selectedSite.id)){
    this.isLineChartLoaderVisible = true;
  }
  setTimeout(()=>{
  if (this.selectedSite.id == 0) {
    analyticsStore.renderQualityChart(this.selectedSite).then(()=>{
      this.responseRecieved = true;
      this.isLineChartLoaderVisible = false;
    });
  }else if(this.selectedSite.id !=0 && scoreDataUpdated.sessionId == this.selectedSite.id){
    analyticsStore.renderQualityChart(this.selectedSite).then(()=>{
      this.responseRecieved = true;
      this.isLineChartLoaderVisible = false;
    });
  }
},4000)
}

async downloadPdfQuality(){
  this.isQualityPdfDownloadInProgress = true;
  this.pdfChartData = this.chartQualityData;
  setTimeout(()=>{
  let parentDiv =  document.getElementById("line-chart-quality-1");
  let parentWidth  = parentDiv && parentDiv.clientWidth;
  let pdfChart =document.getElementById("quality-line-Graph-Print");
  if(pdfChart){
    pdfChart.style.width = parentWidth ? 960 + "px" : "0px";
  }
  this.syncScroll();
    let breakHeight = 900;
    let scale = 1;
    let threshold = 99;
    let width = 0;
    let dateObj = new Date();
    let currentDate = APP_UTILITIES.getSlashedDates(dateObj);
    currentDate = currentDate.replace(/-/g, "_");
    setTimeout(async ()=>{
      await Printutility.getPrintView('quality-line-Graph-Print', breakHeight, width, scale, "Quality Data Dashboard_" + currentDate, "", threshold, true, true)
      this.isQualityPdfDownloadInProgress = false;
  }, 200)
},300)
}
}