import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import APP_CONST from '@/constants/AppConst';
import APP_UTILITIES from "@/utilities/commonFunctions";
import DatepickerComponent from '@/ui-components/datepicker/DatepickerComponent.vue';
import { downLoadFileByScholar, downLoadFileBySite, downLoadFileByWeekReport,  downLoadFileWithScholarDetails, downloadConsolidatedRoaster, downLoadFileOfQualityScreener, addQualityScore, downLoadFileOfQualityScore, editQualityScore,uploadQualityFile} from "@/services/dashboard/dashboardService";
import qualityScoreView from '../../../popupcomponents/qualityScoreView/qualityScoreView.vue';
import UIkit from "uikit";
import dashboardStore from '@/store/modules/dashboardStore';
import DataDashboardWelcome from '../datadashboardwelcome/DataDashboardWelcome.vue';
import DataDashboardScoreAdd from '../datadashboardqualityscoreadd/DataDashboardQualityScoreAdd.vue';
import LocalStorage from "@/utilities/localStorageUtil";
import analyticsStore from '@/store/modules/analyticsStore';
@Component({
  components: {
    'datepicker': DatepickerComponent,
    'quality-score-view': qualityScoreView,
    'welcome-popup' : DataDashboardWelcome,
    'add-score-popup': DataDashboardScoreAdd
  }
})

export default class DataDashboardSidepanel extends Vue {


  private objScreenText: ScreenText = new ScreenText();
  private sidepanelTabs: any[] = [];
  public sessionAdminDownloadFile= ["Daily Attendance by Scholar", "Average Daily Attendance with Scholar Details", "Average Daily Attendance by Site", "Average Daily Attendance by Week"];
  public programAdminDownloadFile= ["Consolidated Scholar Roster", "Average Daily Attendance by Site", "Average Daily Attendance by Week"];
  public downloadFile: any = [];
  public isMobileForAPiCall : boolean = false;
  tabIndex: number = 0;
  selectedTab: string = 'scholar';
  addScoreForm:boolean = false;
  private openSiteDropdown: boolean = false;
  private hover :boolean = false;
  private hoverIndex: string='';
  private hoverOut:boolean = false;
  private hoverVal:string = "";
  private hoverStyleObj: any={};
  public selectedSiteName: string = "Select Session";
  private showScoreAdded: boolean = false;
  programId:number =0;
  siteId:number =0;
  viewPopup:boolean=false
  fileTypeError:boolean=false
  fileSizeError:boolean=false
  fileArray:any=[]
  toolTiphoverText: string = '';
  toolTiphovering: boolean = false;
  toolTipHoverIndex: number = -1;
  isFileUploading=false
  private qualityScoreObjData:any = {
    "programId": 0,
    "siteId": 0,
    "site":"",
    "id":0,
    "week":0,
    "evaluaterName": "",
    "assessmentDate": "",
    "score": "",
    "notes": "",
    "fileId":0
  };

  private qualityScoreObj:any = JSON.parse(JSON.stringify(APP_CONST.QUALITY_SCORE_OBJ));
  private isSubmit:boolean = false;
  private allFieldsRequired:boolean = false;
  private errorInResponse:string = "";
  public siteStartDate:Date = new Date();
  public siteEndDate:Date = new Date();
  private isEditMode = false;
  private qualityScoreId:number = 0;
  private qualityDataTemp:any = {};
  private qualityScoreIdTemp:number = 0;
  editQualityGraphData:any=[]
  isAllSiteGraphData:boolean=false
  week=1;
  @Prop()
  userDetails!: any;

  @Prop()
  siteData!: any;

  @Prop()
  roleId!: number;

  @Prop()
  mobileView!: boolean;

  @Prop()
  showSidepanel!: boolean;

  @Prop()
  scholarGraph!:boolean;

  @Prop()
  selectedSiteId!:number;

  @Prop()
  emptyState!:boolean;

  @Prop()
  siteList!:any

  @Prop()
  activeLeftPanelTab!: string;

  @Prop()
  hidequalityScore!: boolean;

  get selectedProgramData(){
    return analyticsStore.slectedProgram
}

@Watch('selectedProgramData',{deep: true})
onProgramSelection(val: any) {
  this.qualityScoreObj.programId.value = val.programId;
}

  isMobileView:boolean=false
  public userRoles: any = APP_UTILITIES.getCookie("user_role");
  public roleID = this.userRoles ? JSON.parse(this.userRoles)[0].roleId : "";

  mounted(){
    this.dataCheckOnMount();
  }

  dataCheckOnMount(){
    this.downloadFile = (this.roleId == 5)? this.programAdminDownloadFile : this.sessionAdminDownloadFile;
    this.sidepanelTabs = (this.roleId == 5 || this.roleId == 6) ? JSON.parse(JSON.stringify(APP_CONST.TAB_SELECTION_DASHBOARD_SIDEPANEL_ROLE_BASED)) : JSON.parse(JSON.stringify(APP_CONST.TAB_SELECTION_DASHBOARD_SIDEPANEL));
    const activeTab = this.activeLeftPanelTab != undefined && this.activeLeftPanelTab !== '';
    /* istanbul ignore else */
    if(activeTab){
      this.selectedTab =  this.activeLeftPanelTab;
      (this.selectedTab == 'scholar') ? this.tabSelection(APP_CONST.ZERO): this.tabSelection(APP_CONST.ONE);
    }
    let dashboardTabActive= localStorage.getItem('dashboard_tab_active')=='quality'? true:false;
    this.activeTabOnMount(dashboardTabActive);
  }

  activeTabOnMount(dashboardTabActive:boolean){
    /* istanbul ignore else */
    if(dashboardTabActive){
      this.selectedTab = 'quality';
      this.tabSelection(APP_CONST.ONE);
    }
  }

  created(){
    this.isMobileForAPiCall=APP_UTILITIES.mobileAndTabletCheck()
    this.isMobileView=APP_UTILITIES.isMobile()
}


  @Watch('siteList', { deep: true, immediate: true })


  public getScreenText(key: string): string {
    return this.objScreenText.getScreenText(key);
  }

  download(index: number) {
    /* istanbul ignore else */
    if(!this.isMobileForAPiCall)
    {
      let  siteId  = this.selectedSiteId;
      let startDate = (this.roleID == APP_CONST.PROGRAM_ADMIN_ROLE_ID)? ' ' : this.siteData[0].startDate.split(' ')[0];
      let endDate = (this.roleID == APP_CONST.PROGRAM_ADMIN_ROLE_ID)? ' ' : this.siteData[0].endDate.split(' ')[0];
      let  programId  = this.selectedProgramData.programId;
      const payload = { programId , classId: 0, siteId, startDate, endDate, sortField:"firstName", sortDir:1, group:1, isReport:true};
      /* istanbul ignore else */
      if(this.downloadFile[index] === this.sessionAdminDownloadFile[0] && this.roleId != 5){
        downLoadFileByScholar(payload).then((response) => {
            APP_UTILITIES.downLoadFile(response, APP_CONST.SCHOLARS, APP_CONST.FILE_HEADER_OF_SCHOLAR)
        });
    }
    this.DownloadFileProgramAdminConditions(index, programId);
    this.DownloadFileSessionAdminConditions(index, programId, payload);
  }
}

  private DownloadFileSessionAdminConditions(index: number, programId: number, payload: { programId: number; classId: number; siteId: number; startDate: any; endDate: any; sortField:string; sortDir:number; group:number; isReport:boolean; }) {
    if (this.downloadFile[index] === this.sessionAdminDownloadFile[3]) {
      const payloadForScholar = { programId, adaReportType: 2 };
      downLoadFileByWeekReport(payloadForScholar).then((response) => {
        APP_UTILITIES.downLoadFile(response, APP_CONST.WEEK, APP_CONST.FILE_HEADER_OF_WEEK);
      });
    }
    if (this.downloadFile[index] === this.sessionAdminDownloadFile[1] && this.roleId != 5) {
      downLoadFileWithScholarDetails(payload).then((response) => {
        APP_UTILITIES.downLoadFile(response, APP_CONST.SHOLAR_DETAILS, APP_CONST.FILE_HEADER_WITH_SCHOLAR_DETAILS);
      });
    }
  }

  private DownloadFileProgramAdminConditions(index: number, programId: number) {
    if (this.downloadFile[index] === this.programAdminDownloadFile[1]) {
      const payloadForSite = { programId, adaReportType: 1 };
      downLoadFileBySite(payloadForSite).then((response) => {
        APP_UTILITIES.downLoadFile(response, APP_CONST.SITE, APP_CONST.FILE_HEADER_OF_SITE);
      });
    }
    if (this.downloadFile[index] === this.programAdminDownloadFile[0]) {
      const {accountId} = APP_UTILITIES.coreids();
      const fileName = this.userDetails.progName + "_" + APP_CONST.CONSOLIDATED_SCHOLARS;
      const payloadForConsolidatedScholar = { accountId:accountId,siteId: 0, programId, step: -1, fileType: 2 };
      downloadConsolidatedRoaster(payloadForConsolidatedScholar).then((response) => {
        APP_UTILITIES.downLoadFile(response, fileName, APP_CONST.FILE_HEADER_CONSOLIDATED_SCHOLAR);
      });
    }
  }

  closeSidepanel(){
    this.showSidepanel = false;
  }

  getSelectedTabDetails(id: number) {
    if ((id == 0 && this.selectedTab != 'scholar') || (this.mobileView && this.scholarGraph)) {
      this.selectedTab = 'scholar';
      LocalStorage.deleteFromLocalStorage("dashboard_tab_active");
      this.tabSelection(id);
    }
    else if ((id == 1 && this.selectedTab != 'quality') || (this.mobileView && !this.scholarGraph)) {
      this.selectedTab = 'quality';
      LocalStorage.saveInLocalStorage("dashboard_tab_active", 'quality');
      this.tabSelection(id);
      let { programId, siteId } = APP_UTILITIES.coreids();
      this.programId = programId;
      this.siteId = siteId;
      this.checkForPopupState({ programId: programId, siteId: siteId })
    }
    this.closePopup()
    this.$emit('showGraphInfo', this.selectedTab);
  }


  tabSelection(id: number) {
    this.sidepanelTabs.forEach((tab, index) => {
      if (id === index) {
        tab.active = APP_CONST.TRUE;
        this.tabIndex = index;
      } else {
        tab.active = APP_CONST.FALSE;
      }
    })
  }

  addNewScore(){
    this.addScoreForm = true;
    this.resetToAddNew();
  }

  openSiteDropdownSideP(close ? :boolean){
    const closeStatus = close ? APP_CONST.FALSE : APP_CONST.TRUE;
    this.openSiteDropdown = this.openSiteDropdown ? APP_CONST.FALSE : (closeStatus);
  }

  selectSite(site:any) {
    if (site) {
      this.callHoverOut();
      this.selectedSiteName = site.name;
      this.qualityScoreObj.siteId.value = site.id;
      this.siteStartDate = site.startDate? new Date(site.startDate.split(" ")[0]) : new Date();
      const checkDate = new Date(site.endDate) > new Date() ? new Date() : site.endDate ? new Date(site.endDate.split(" ")[0]) : new Date();
      this.siteEndDate = site.endDate ? (checkDate)  : new Date();
      this.qualityScoreObj.assessmentDate.value = "";
      this.openSiteDropdown = false;
    }
  }

  callHover(hoverText:string,$event:any){
    if($event.target.offsetWidth < $event.target.scrollWidth){
      this.hover = true;
      this.hoverIndex = hoverText;
      const boundBox = $event.target.getBoundingClientRect();
      const coordX = boundBox.left;
      const coordY = boundBox.top;
      this.hoverStyleObj = {
          top:(coordY + 40).toString() + "px",
          left:(coordX + 20).toString() + "px"
      }
    }
  }

  callHoverOut(){
    this.hover = false;
  }

  checkScoreField(score:any){
    return score >= 1 && score <=4 && (score.length == 1 || score.length ==3) && score.match(/\d*\.?[0-9]{1}$/);
  }

  submitScore(){
    let programId = this.selectedProgramData.programId;
    let reqKey = APP_CONST.QUALITY_REQ_KEY;
    this.errorInResponse = "";
    for (let keyElement in this.qualityScoreObj) {
      if(reqKey.indexOf(keyElement) !== APP_CONST.MINUS_ONE){
        if(this.qualityScoreObj[keyElement].value == "" || this.qualityScoreObj[keyElement].value == 0){
          this.qualityScoreObj[keyElement].errorStatus = true;
          this.allFieldsRequired = true;
          this.fileTypeError=false
          this.fileSizeError=false
          return;
        }else if(keyElement == "score" && this.qualityScoreObj[keyElement].value){
          if(this.checkScoreField((this.qualityScoreObj[keyElement].value).toString())){
            this.qualityScoreObj[keyElement].errorStatus = false;
          }else{
            this.qualityScoreObj[keyElement].errorStatus = true;
            return;
          }
        }else{
          this.qualityScoreObj[keyElement].errorStatus = false;
        }
      } else if(keyElement == "evaluaterName" && this.qualityScoreObj[keyElement].value){
        let checkName = APP_UTILITIES.validateAlphaOnly(this.qualityScoreObj[keyElement].value);
        if(checkName == false){
          this.qualityScoreObj[keyElement].errorStatus = !checkName;
          return;
        }else{
          this.qualityScoreObj[keyElement].errorStatus = false;
        }
      }else{
        this.qualityScoreObj[keyElement].errorStatus = false;
      }
    }
    this.qualityScoreObjData = {
      "programId": programId,
      "siteId": this.qualityScoreObj.siteId.value,
      "site": this.selectedSiteName,
      "id": this.qualityScoreObj.id.value,
      "evaluaterName": this.qualityScoreObj.evaluaterName.value,
      "assessmentDate": this.qualityScoreObj.assessmentDate.value,
      "score": this.qualityScoreObj.score.value,
      "notes": this.qualityScoreObj.notes.value,
      "fileId":this.qualityScoreObjData.fileId,
      "week": this.qualityScoreObj.week.value
    }
    this.allFieldsRequired = false;
    /* istanbul ignore else */
    if(this.qualityScoreId == 0){
      addQualityScore(this.qualityScoreObjData).then((res : any) => {
        /* istanbul ignore else */
        if (res.status === APP_CONST.RESPONSE_200) {
          this.showScoreAdded = res.data.isAddedFirstTime;
          this.qualityScoreId = res.data.id;
          this.qualityScoreObjData.week = res.data.week
          this.week = parseFloat(res.data.week + "." + (res.data.weekDayNumber != 1 ? res.data.weekDayNumber : 0));
          this.$emit('updateGraphQuality', this.qualityScoreObjData, this.week, res.data.averageScore);
          this.$emit('updateMobileGraphQuality', this.qualityScoreObjData, this.week, res.data.averageScore);
          this.errorInResponse = "";
          this.addScoreForm = false;
          this.isSubmit = true;
          this.qualityScoreObjData.fileId=0;
          this.isEditMode = false;
          this.checkAddScorePopUpState(this.showScoreAdded);
        }
        const responseCode = res.status === APP_CONST.RESPONSE_400 || res.status === APP_CONST.RESPONSE_406;
        if(responseCode){
          this.errorInResponse = res.data;
        }
      })
    } else{
      editQualityScore(this.qualityScoreObjData, this.qualityScoreId).then((res : any) => {
        if (res.status === APP_CONST.RESPONSE_200) {
          this.week = parseFloat(res.data.week + "." + (res.data.weekDayNumber != 1 ? res.data.weekDayNumber : 0));
          this.$emit('updateGraphQuality', this.qualityScoreObjData, this.week, res.data.averageScore);
          this.$emit('updateMobileGraphQuality', this.qualityScoreObjData, this.week, res.data.averageScore);
          this.addScoreForm = false;
          this.errorInResponse = "";
          this.isSubmit = true;
          this.qualityScoreObjData.fileId=0;
          this.isEditMode = false;
          this.qualityScoreObjData.week = res.data.week;
        }
        const responseCode = res.status === APP_CONST.RESPONSE_400 || res.status === APP_CONST.RESPONSE_406;
        if(responseCode){
          this.errorInResponse = res.data;
        }
      }).catch((error : any) => {
        console.log('error ', error)
      })
    }
  }

  formateDate(e:any){
    this.qualityScoreObj.assessmentDate.value = APP_UTILITIES.getFullDateWith2DigitYear(e)
  }

  downloadQualityScreenerPDF(){
    if(!this.isMobileView){
    if(this.roleID == APP_CONST.FIVE || this.roleID == APP_CONST.SIX){
      downLoadFileOfQualityScreener().then((response) => {
          APP_UTILITIES.downloadPDF(response, "Quality Screener")
      });
    }
  }
  }

  checkForPopupState(payload: {  programId: number, siteId: number }){
    dashboardStore.showWelcomePopup(payload).then((data: any) => {
      /* istanbul ignore else */
      if(data.data==false){
      if(UIkit.modal('#quality-welcome') ) {
        UIkit.modal('#quality-welcome').show();
      }
    }
  })
}


 openPopup(){
      this.viewPopup = true;
    }

    closePopup(){
      this.viewPopup = false;
    }
    fileHandler(file: any) {
      let checkBothArrayLength=this.fileArray.length
      /* istanbul ignore else */
     if(checkBothArrayLength<=1){
       this.fileTypeError=false
       this.fileSizeError=false
      /* istanbul ignore else */
     if(this.checkFileType(this.getFileNameAndtype(file[0].name).ext)){
     const size = file[0].size <= APP_CONST.UPLOAD_FILE_MB;
       if(size) {
        this.isFileUploading=true
            var formData = new FormData();
            formData.append('formFile', file[0]);
           this.callUploadFileApi(formData,file[0])
       } else{
        this.allFieldsRequired = false;
         this.fileTypeError=false
         this.fileSizeError=true

       }
     }else{
      this.allFieldsRequired = false;
       this.fileSizeError=false
       this.fileTypeError=true
     }
    }

   }
   callUploadFileApi(formData:any,file:any){
    uploadQualityFile(formData).then((response: any) => {
      /* istanbul ignore else */
      if(response.status == APP_CONST.RESPONSE_200){
        this.allFieldsRequired = false;
        this.qualityScoreObjData.fileId=response.data.fileId
        this.fileArray.push({fileId:response.data.fileId,fileUrl:response.data.fileUrl,fileName:file.name})
      }
      this.isFileUploading=false
    })
  }
   checkFileType(ext:string){
    let fileType=["jpg","pdf","jpeg","png"]
    return fileType.some((filetype: any) => {
      return filetype.toLowerCase()==ext.toLowerCase()
   })
   }
  getFileNameAndtype(name: any) {
    const lastDot = name.lastIndexOf('.');
    const fileName = name.substring(0, lastDot);
    const ext = name.substring(lastDot + 1);
    return { fileName: fileName, ext: ext }
}
deleteFileHandler(fileObj:any,index:number,event:any){

  let files=this.fileArray.filter((obj:any)=>obj.id!=fileObj.id)
  this.qualityScoreObjData.fileId=0
  this.fileArray=files
  /* istanbul ignore else */
  if(files.length==0){
    this.fileTypeError=false
    this.fileSizeError=false
  }
  event.stopPropagation();
}
downLoadFile(ulr:string,localUrl:string){
  /* istanbul ignore else */
  if(!this.mobileView){
 window.open(ulr,'_self')
  }
  }
  toolTipcallHover(index:number, text: string) {
    this. toolTiphoverText= text;
    this.toolTiphovering= true;
    this.toolTipHoverIndex = index;
}

toolTipcallHoverOut() {
    this. toolTiphoverText= '';
    this. toolTiphovering= false;
    this.toolTipHoverIndex = -1;
}

onlyNumberInRange($event: any) {
  let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
  let decimalPlaces = $event.currentTarget.value.indexOf(".") > -1 ? $event.currentTarget.value.split("."): [];
  const keyCodeVal = keyCode != 46 || (keyCode == 46 && $event.currentTarget.value.indexOf(".") > -1);
  const targetVal = $event.currentTarget.value.length == 1 && keyCode != 46 && decimalPlaces.length == 0;
  const keyCode49_52 = $event.currentTarget.value == "" && (keyCode < 49 || keyCode > 52);
  if (((keyCodeVal && keyCode < 48) || keyCode > 57) || (decimalPlaces.length > 0 && decimalPlaces[1].length) || targetVal || keyCode49_52) {
    $event.preventDefault();
  }
}

downloadQualityScoreCSV(){
  if(!this.isMobileView){
  if(this.roleID == APP_CONST.FIVE || this.roleID == APP_CONST.SIX ){
     let  programId  = this.selectedProgramData.programId;
     const payload = { programId, siteId : this.selectedSiteId};
     downLoadFileOfQualityScore(payload).then((response:any) => {
         APP_UTILITIES.downLoadFile(response, APP_CONST.QUALITY_SCORES_CSV, "")
     });
   }
  }
}
editModeFromGraph(qualityData:any, qualityScoreId:number,week:any){
  this.fileArray=[]
  this.isAllSiteGraphData=true
  this.editQualityGraphData=qualityData
  this.fileArray=[]
  this.isSubmit = false;
  this.isEditMode = false;
  this.addScoreForm = false;


}
editMode(qualityData:any, qualityScoreId:number){
  this.qualityDataTemp = JSON.parse(JSON.stringify(qualityData));
  /* istanbul ignore else */
  if(this.siteList && this.siteList.length > 0){
    const selectedSiteData = this.siteList.find((site: any) => site.id === this.qualityDataTemp.siteId);
    this.siteStartDate = selectedSiteData.startDate? new Date(selectedSiteData.startDate) : new Date();
    const checkDate = new Date(selectedSiteData.endDate) > new Date() ? new Date() : new Date(selectedSiteData.endDate);
    this.siteEndDate = selectedSiteData.endDate ? (checkDate)  : new Date();
  }
  this.qualityScoreIdTemp = qualityData.id? qualityData.id:qualityScoreId;
  this.fileArray=[]
  this.errorInResponse = '';
  this.qualityScoreId =  qualityData.id? qualityData.id:qualityScoreId;
  this.isSubmit = false;
  this.isEditMode = true;
  this.addScoreForm = true;
  this.qualityScoreObj.programId.value = qualityData.programId;
  this.qualityScoreObj.siteId.value = qualityData.siteId;
  this.qualityScoreObj.site.value = qualityData.site;
  this.qualityScoreObj.evaluaterName.value = qualityData.evaluaterName;
  this.qualityScoreObj.assessmentDate.value =  APP_UTILITIES.getFullDateWith2DigitYear(qualityData.assessmentDate);
  this.qualityScoreObj.score.value = qualityData.score;
  this.qualityScoreObj.notes.value = qualityData.notes;
  this.qualityScoreObj.id.value=qualityData.id;
  this.qualityScoreObj.week.value=qualityData.week;
 if(qualityData.fileId){
  this.qualityScoreObjData.fileId=qualityData.fileId
  this.fileArray.push({fileId:qualityData.fileId,fileUrl:qualityData.fileUrl,fileName:qualityData.fileName})
  }
  this.fileTypeError=false
  this.fileSizeError=false
}
mergeObj(qualityScoreObj:any,editQualityGraphData:any){
  if(this.isAllSiteGraphData && this.isSubmit){
    let findIndex=editQualityGraphData.findIndex((obj:any)=>obj.id==this.qualityScoreId)
    this.editQualityGraphData[findIndex]= {...this.qualityScoreObjData,...this.fileArray[0]}
       return  this.editQualityGraphData
  }
  return  this.editQualityGraphData
}


resetToAddNew(){
  this.selectedSiteName= "Select Session";
  this.isSubmit= false;
  this.qualityScoreObjData = {
    "programId": this.selectedProgramData.programId,
    "siteId": 0,
    "evaluaterName": "",
    "assessmentDate": "",
    "score": "",
    "notes": "",
    "fileId":0
  };
  this.qualityScoreObj = JSON.parse(JSON.stringify(APP_CONST.QUALITY_SCORE_OBJ));
  this.qualityScoreObj.programId.value = this.selectedProgramData.programId;
  this.allFieldsRequired = false;
  this.errorInResponse = "";
  this.siteStartDate = new Date();
  this.siteEndDate = new Date();
  this.isEditMode = false;
  this.qualityScoreId = 0;
  this.editQualityGraphData=[]
  this.isAllSiteGraphData=false
  this.fileTypeError=false
  this.fileSizeError=false
    this.qualityScoreObjData.fileId=0
    this.fileArray=[]

}

  deleteInfoCheck(id:number, qualityScoreObj: any){
    if(this.qualityScoreId){
      this.qualityScoreObjData = {
        "programId": this.selectedProgramData.programId,
        "siteId": 0,
        "evaluaterName": "",
        "assessmentDate": "",
        "score": "",
        "notes": "",
        "fileId":0
      };
      this.isSubmit = false;
      this.isEditMode= false;
    }
    let data=JSON.parse(JSON.stringify(this.editQualityGraphData));
    this.editQualityGraphData=data.filter((obj:any)=>obj.id!=id);
    if(this.editQualityGraphData.length === 0){
      this.isAllSiteGraphData = false;
    }
    this.$emit('updateGraphQuality', qualityScoreObj);
    this.$emit('updateMobileGraphQuality', qualityScoreObj);
  }

  checkAddScorePopUpState(showScoreAdded : boolean){
    this.$emit('userDetails', this.userDetails);
     /* istanbul ignore else */
    if(showScoreAdded) {
      /* istanbul ignore else */
      if(UIkit.modal('#datadashboard-add-qualityscore')){
        UIkit.modal('#datadashboard-add-qualityscore').show();
      }
     }
  }

  closeAddScorePopUp(ev: {id: string}){
    /* istanbul ignore else */
    if(UIkit.modal(`#${ev.id}`)) {
      UIkit.modal(`#${ev.id}`).hide();
    }
  }

  cancelChages(){
    if(this.isEditMode == true && this.isAllSiteGraphData == false){
      this.editMode(this.qualityDataTemp , this.qualityScoreIdTemp);
      this.isSubmit = true;
      this.addScoreForm = false;
      this.isEditMode = false;
    } else if(this.isAllSiteGraphData == true){
      this.qualityScoreObjData = {
        "programId": this.userDetails ? this.userDetails.programId : 0,
        "siteId": this.qualityDataTemp.siteId,
        "site": this.qualityDataTemp.site,
        "id": this.qualityDataTemp.id,
        "evaluaterName": this.qualityDataTemp.evaluaterName,
        "assessmentDate": this.qualityDataTemp.assessmentDate,
        "score": this.qualityDataTemp.score,
        "notes": this.qualityDataTemp.notes,
        "fileId":this.qualityDataTemp.fileId,
        "week": this.qualityDataTemp.week
      }
      this.editMode(this.qualityDataTemp , this.qualityScoreIdTemp);
      this.isEditMode = false;
      this.addScoreForm = false;
    } /* istanbul ignore else */
    else if(this.isAllSiteGraphData == false && this.isEditMode == false){
          this.addScoreForm = false;
          this.resetToAddNew();
        }
    }
}